<template>
  <oryl-container>
    <v-row>
      <v-col cols="12" lg="8" offset-lg="2">
        <v-card class="px-3" :loading="loading">
          <v-layout d-flex justify-space-between align-center>
            <v-btn icon :disabled="tilePage === null" to="/home" color="primary">
              <v-icon x-large>
                mdi-home
              </v-icon>
            </v-btn>
            <v-breadcrumbs :items="breadcrumbs"/>
            <v-btn icon :disabled="tilePage === null" @click="gotoParent" color="primary">
              <v-icon x-large>
                mdi-arrow-left
              </v-icon>
            </v-btn>
          </v-layout>
        </v-card>

        <grid-layout
                :layout.sync="tiles"
                :col-num="6"
                :row-height="100"
                :is-draggable="edit"
                :is-resizable="edit"
                :vertical-compact="true"
                :margin="[10, 10]"
                v-model="tiles"
                :class="{backgroundOverlay: edit}"
                class="mb-3 mx-0"
        >
          <grid-item
                  v-for="tile in tiles"
                  :key="tile.i"
                  :x="tile.x"
                  :y="tile.y"
                  :w="tile.w"
                  :h="tile.h"
                  :i="tile.i"
          >
            <oryl-tile :value="tile" :edit="edit" @click="selectTile(tile)" :selected="tile === selected"/>
          </grid-item>
        </grid-layout>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-sheet v-if="edit" color="white" elevation="3" tile class="mb-3">
          <v-toolbar color="primary" dark dense>Bearbeiten</v-toolbar>
          <div class="pa-3">
            <v-btn color="primary" block @click="addTile" class="mb-3">
              Neue Kachel
            </v-btn>
            <v-layout d-flex v-if="selected.i">
              <v-layout d-flex column>
                <v-text-field label="Bild" v-model="selected.img"/>
                <v-file-input label="Bild" v-model="file" :loading="loadingFile" clearable/>
                <v-radio-group v-model="selected.type">
                  <v-radio value="link" label="Link"/>
                  <v-radio value="search" label="Suche"/>
                  <v-radio value="menu" label="Menü"/>
                  <v-radio value="text" label="Text"/>
                </v-radio-group>
                <v-text-field label="Text" v-model="selected.typevalue"/>
                <v-btn color="red" block @click="removeTile(selected)" dark>
                  Kachel löschen
                </v-btn>
              </v-layout>

              <v-divider vertical class="mx-5"/>

              <v-layout d-flex column justify-start v-if="selected.i > 0">
                <v-text-field v-model="selectedLang.lang" label="Sprachcode"/>
                <v-text-field v-model="selectedLang.txt" label="Text"/>
                <v-textarea v-model="selectedLang.subTxt" label="Subtext"/>
                <v-btn color="primary" @click="addTileLanguage">
                  Übersetzung hinzufügen / aktualisieren
                </v-btn>
              </v-layout>
            </v-layout>

            <v-divider class="my-3" v-if="selected.i"/>

            <v-layout d-flex column v-if="menus.length">
              <v-select v-model="menuLang.menu" :items="menus" item-value="menu" item-text="menu" label="Menü"/>
              <v-text-field v-model="menuLang.lang" label="Sprachcode"/>
              <v-text-field v-model="menuLang.text" label="Text"/>
              <v-btn color="primary" @click="addMenuLanguage">
                Übersetzung hinzufügen / aktualisieren
              </v-btn>
            </v-layout>

            <v-divider class="my-3"/>

            <v-text-field v-model="allTilesSearch" class="mx-3" label="Suche" clearable append-icon="mdi-magnify"/>
            <v-data-table :headers="allTilesHeaders" :items="allTiles" :search="allTilesSearch" :loading="allTielsLoading">
              <template v-slot:item.img="{item}">
                <v-img :src="item.img" height="3em" width="3em" contain/>
              </template>
            </v-data-table>
          </div>
        </v-sheet>
      </v-col>
    </v-row>

    <v-btn fab fixed bottom left color="indigo" dark @click="editMode" v-if="$store.state.LoginStore.admin">
      <v-icon v-show="!edit">
        mdi-pencil
      </v-icon>
      <v-icon v-show="edit">
        mdi-floppy
      </v-icon>
    </v-btn>
  </oryl-container>
</template>

<script>
  import VueGridLayout from 'vue-grid-layout'
  import OrylTile from "../../components/OrylTile";
  import OrylContainer from "../../components/OrylContainer";

export default {
  name: 'Info',
  components: {
    OrylContainer,
    OrylTile,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
  },
  data: function () {
    return {
      tiles: [],
      menu: {},
      allTiles: [],
      allTilesHeaders: [
        {text: 'Menü', value: 'menu'},
        {text: 'Text', value: 'txt'},
        {text: 'Subtext', value: 'subtxt'},
        {text: 'Bild', value: 'img'},
        {text: 'Typ', value: 'type'},
        {text: 'Wert', value: 'value'},
      ],
      allTilesSearch: '',
      allTielsLoading: true,
      edit: false,
      selected: null,
      selectedLang: {
        lang: '',
        txt: '',
        subTxt: '',
      },
      menus: [],
      menuLang: {
        menu: '',
        lang: '',
        text: '',
      },
      search: {
        search: '',
        type: 'full'
      },
      tilePage: 'Kontakt',
      parent: [],
      breadcrumbs: [
        {
          text: this.$i18n.t('breadcrumbsHome'),
          disabled: true
        }
      ],
      loading: false,
      file: [],
      loadingFile: false
    }
  },
  methods: {
    gotoHome: function () {
      this.tilePage = null;
      this.parent = [];
      this.breadcrumbs = [this.breadcrumbs.shift()];
    },
    gotoParent: function () {
      this.tilePage = this.parent.pop();
      if (typeof(this.tilePage) === 'undefined') {
        this.$router.replace('/home');
      }
      this.breadcrumbs.pop();
    },
    editMode: function () {
      this.selected = {i: 0, x: 0, y: 0, w: 0, h: 0, img: '', type: '', typevalue: '', parent: null};

      if (!this.edit) {
        this.edit = true;
        this.$store.dispatch('TileStore/getAllTiles')
          .then((response) => {
            this.allTiles = response;
            this.allTielsLoading = false;
          })
        this.$store.dispatch('TileStore/getAllMenus')
        .then((response) => {
          this.menus = response;
        })
        return;
      }

      this.edit = false;
      this.$store.dispatch('TileStore/saveTiles', this.tiles)
        .then(function () {
          return this.$store.dispatch('TileStore/getTiles', this.tilePage);
        }.bind(this))
        .then(function (response) {
          this.tiles = response.tiles;
        }.bind(this));
    },
    selectTile: function (tile) {
      if (this.edit) {
        this.selected = tile;
        this.selectedLang.lang = this.$i18n.locale;
        this.selectedLang.txt = tile.txt;
        this.selectedLang.subTxt = tile.subtxt;
        return;
      }

      switch (tile.type) {
        case 'link':
          window.open(tile.typevalue, '_blank');
          break;
        case 'search':
          this.$store.commit('ShopStore/setPreset', {search: tile.typevalue, type: 'internal'});
          this.$router.push('/shop')
          break;
        case 'menu':
          this.parent.push(this.tilePage);
          this.tilePage = tile.typevalue;
          break;
      }
    },
    addTile: function () {
      this.tiles.push({i: Math.floor(Math.random() * -1000), x: 0, y: 100, w: 1, h: 1, img: '', type: 'link', typevalue: '', parent: this.tilePage});
    },
    removeTile: function (selected) {
      this.tiles = this.tiles.filter(function (item) {
        return item !== selected;
      })
      this.selected = {i: 0, x: 0, y: 0, w: 0, h: 0, img: '', type: '', typevalue: '', parent: this.tilePage};
      this.$store.dispatch('TileStore/removeTile', selected);
    },
    addTileLanguage: function () {
      this.$store.dispatch(
        'TileStore/addTranslation',
        {
          i: this.selected.i,
          ...this.selectedLang
        }
      )
      .then(() => {
        this.selectedLang.lang = '';
        this.selectedLang.txt = '';
        this.selectedLang.subTxt = '';
      });
    },
    addMenuLanguage: function () {
      this.$store.dispatch('TileStore/addMenuTranslation', this.menuLang)
      .then(() => {
        this.menuLang.menu = '';
        this.menuLang.lang = '';
        this.menuLang.text = '';
      });
    },
  },
  created() {
    this.loading = true;
    this.$store.dispatch('TileStore/getTiles', this.tilePage)
      .then((response) => {
        this.menu = response.menu.text;
        this.tiles = response.tiles;
        this.loading = false;
      });
  },
  watch: {
    tilePage: function () {
      if (this.tilePage === '') {
        return;
      }

      this.loading = true;
      this.$store.dispatch('TileStore/getTiles', this.tilePage)
        .then((response) => {
          this.menu = response.menu.text ? response.menu.text : response.tiles[0].parent;
          this.tiles = response.tiles;
          this.loading = false;

          if (
            this.menu
            && this.breadcrumbs.slice(-1)[0].text !== this.menu
          ) {
            this.breadcrumbs.push({
              text: this.menu,
              disabled: true,
            });
          }
        });
    },
    search: function () {
      this.$store.commit('ShopStore/setPreset', this.search);
      this.$router.push('/shop');
    },
    file: function () {
      if (!(this.file instanceof File)) {
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        this.selected.img = e.target.result
        this.file = [];
        this.loadingFile = false;
      };

      this.loadingFile = true;
      reader.readAsDataURL(this.file);
    }
  }
}
</script>

<style scoped>
  .backgroundOverlay {
    background-color: lightgray;
  }
</style>
